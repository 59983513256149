import React from "react";
import { Link } from "gatsby";
import LoginButton from "./LoginButton.js";
import MenuDrawer from "./MenuDrawer.js";
import Logo from "../img/logo.svg";
import MobileLogo from "../img/logo-mobile.svg";
import Button from "@material-ui/core/Button";
import { Icon, InlineIcon } from "@iconify/react";
import userCircleSolid from "@iconify/icons-la/user-circle-solid";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      activeClass: 'top',
      navBarActiveClass: "",
      hideMenu: props.hideMenu,
      frontPage: props.frontPage,
    };
  }

  componentDidMount(){
    window.addEventListener('scroll', () => {
       let activeClass = 'normal';
       if(window.scrollY === 0){
           activeClass = 'top';
       }
       this.setState({ activeClass });
    });
}

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: "is-active",
            })
          : this.setState({
              navBarActiveClass: "",
            });
      }
    );
  };

  render() {
    return (
      <nav className={`navbar ${this.state.activeClass} ${this.state.frontPage && "fp"}`} role="navigation" aria-label="main-navigation">
        <div className="nav-container">
          <div className="navbar-brand">
            <Link to="/" title="Logo">
              {/* <img  src={logo} alt="Samfélagsgróðurhús" />*/} 
              <Logo className="logo"/>
              <MobileLogo className="mobile-logo" />
            </Link>
          </div>
          <div id="navMenu" className="navbar-menu">
            {!this.state.hideMenu && (
              <div className="navbar-end has-text-centered">
                <LoginButton />
                <MenuDrawer></MenuDrawer>
              </div>
            )}
          </div>
        </div>
      </nav>
    );
  }
};

export default Navbar;
