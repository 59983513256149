import React from "react";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby";
import phoneIcon from "@iconify/icons-bi/phone";
import mail24 from "@iconify/icons-octicon/mail-24";
import { Icon, InlineIcon } from "@iconify/react";
import locationCompany from "@iconify/icons-carbon/location-company";
import asset from "../img/AssetMynd.png";
import facebookIcon from "@iconify/icons-la/facebook";
import Logo from "../img/GreenLogo.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer has-text-white-ter">
        <div
          className="background-image"
          style={{
            backgroundImage: `url(${asset})`,
            backgroundPosition: `top right`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "225px",
            height: "100%",
            marginTop: "-50px",
            width: "100%",
            position: "absolute",
            display: "block",
          }}
        ></div>
        <Grid container className="has-text-white-ter container">
          <Grid item md={4} xs={12}>
            <div className="footer-logo">
              <Logo />
            </div>
            <div>
              <a href="https://www.google.com/maps/place/%C3%81rleynir+8,+112+Reykjav%C3%ADk/@64.138366,-21.7668147,17z/data=!3m1!4b1!4m5!3m4!1s0x48d6740a59f0640b:0xe1bad1c454d0254c!8m2!3d64.1383637!4d-21.7646208">
                <Icon
                  icon={locationCompany}
                  style={{
                    float: "left",
                    color: "#0c8868",
                    fontSize: "46px",
                    paddingRight: "7px",
                    marginBottom: "70px",
                  }}
                />
                <p className="footer-item-name">Skrifstofa</p>
                <p>Árleynir 8,</p>
                <p>112 Reykjavík</p>
              </a>
            </div>
          </Grid>
          <Grid item md={7} xs={12}>
            <div style={{ marginBottom: "3em" }}>
              <a href="tel:8498871">
                <Icon
                  icon={phoneIcon}
                  style={{
                    float: "left",
                    color: "#0c8868",
                    fontSize: "42px",
                    margin: "6.5px",
                    paddingRight: "7px",
                  }}
                />
                <p className="footer-item-name">Símanúmer</p>
                <p>849 8871</p>
              </a>
            </div>
            <div style={{ marginBottom: "3em" }}>
              <a href="mailto:info@samfelagsgrodurhus.com">
                <Icon
                  icon={mail24}
                  style={{
                    float: "left",
                    color: "#0c8868",
                    fontSize: "36px",
                    margin: "9.5px",
                    paddingRight: "7px",
                  }}
                />
                <p className="footer-item-name">Sendu okkur línu</p>
                <p>info@samfelagsgrodurhus.com</p>
              </a>
            </div>
            <div style={{ marginBottom: "5em" }}>
              <a href="https://www.facebook.com/samfelagsgrodurhus">
                <span>
                  <Icon
                    icon={facebookIcon}
                    style={{
                      float: "left",
                      color: "#0c8868",
                      fontSize: "55px",
                      paddingRight: "7px",
                    }}
                  />
                </span>
                <p className="footer-item-name">Fygldust með</p>
                <p>Samfélagsgróðurhús á Facebook</p>
              </a>
            </div>
          </Grid>
          <Grid item md={3}></Grid>
        </Grid>

        <div className="has-text-centered footer-bottom">
          <p>Samfélagsgróðurhús ehf.</p>
        </div>
      </footer>
    );
  }
};

export default Footer;
