import React from "react";
import { Link } from "gatsby";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import { Icon, InlineIcon } from "@iconify/react";
import userCircleSolid from "@iconify/icons-la/user-circle-solid";
import closeLine from "@iconify/icons-clarity/close-line";

export default function LoginButton() {
  return (
    <Link to="/innskraning" title="Mitt svæði">
      <Button
        variant="contained"
        color="primary"
        className="login-btn"
        startIcon={
          <Icon
            icon={userCircleSolid}
            style={{ marginLeft: "-5px", color: "#ffffff", fontSize: "30px" }}
          />
        }
      >
        Mitt svæði
      </Button>
    </Link>
  );
}
