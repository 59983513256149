import React from "react";
import LoginButton from "./LoginButton.js";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from "gatsby";
import IconButton from "@material-ui/core/IconButton";
// import MenuIcon from "@material-ui/icons/Menu";
import MenuIcon from "../img/icons/menu-button.svg";
import closeLine from "@iconify/icons-clarity/close-line";
import { Icon, InlineIcon } from "@iconify/react";
import shoppingBagLine from "@iconify/icons-clarity/shopping-bag-line";
import plantOutline from "@iconify/icons-teenyicons/plant-outline";
import { Switch, FormControlLabel } from "@material-ui/core";
import mailIcon from "@iconify/icons-codicon/mail";
import educationIcon from "@iconify/icons-carbon/education";

const useStyles = makeStyles({
  bold: {
    fontWeight: "bold !important",
  },
  list: {
    width: 320,
    height: "100%",
    paddingLeft: "20px",
    paddingRight: "0",
    color: "white",
    fontWeight: "bold !important",
    backgroundColor: "#15362B",
  },
  fullList: {
    width: "auto",
  },
  divider: {
    background: "rgba(255, 255, 255, 0.2)",
  },
});

export default function MenuDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "top" || anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          height: "80px",
        }}
      >
        <LoginButton />{" "}
        <IconButton>
          {" "}
          <Icon
            icon={closeLine}
            style={{ display: "inline", color: "#0c8868", fontSize: "30px" }}
          />{" "}
        </IconButton>{" "}
      </div>

      <List>
        <Link to="/store">
          <ListItem className={classes.bold} button>
            <ListItemIcon>
              <Icon
                icon={shoppingBagLine}
                style={{ color: "#ffffff", fontSize: "28px" }}
              />
            </ListItemIcon>
            <ListItemText className="list-item" primary="VERSLUN" />
          </ListItem>
        </Link>
        <Divider className={classes.divider} />
        <Link to="/about">
          <ListItem button>
            <ListItemIcon>
              <Icon
                icon={plantOutline}
                style={{ color: "#ffffff", fontSize: "24px" }}
              />
            </ListItemIcon>
            <ListItemText className="list-item" primary="UM FYRIRTÆKIÐ" />
          </ListItem>
        </Link>

        <Divider className={classes.divider} />
        <Link to="/products">
          <ListItem button>
            <ListItemIcon>
              <Icon
                icon={educationIcon}
                style={{ color: "#ffffff", fontSize: "28px" }}
              />
            </ListItemIcon>
            <ListItemText className="list-item" primary="SPURT OG SVARAÐ" />
          </ListItem>
        </Link>
        <Divider className={classes.divider} />
        <Link to="/contact">
          <ListItem button>
            <ListItemIcon>
              <Icon
                icon={mailIcon}
                style={{ color: "#ffffff", fontSize: "28px" }}
              />
            </ListItemIcon>
            <ListItemText className="list-item" primary="HAFA SAMBAND" />
          </ListItem>
        </Link>
        <Divider className={classes.divider} />
      </List>
    </div>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer("right", true)} className="menu-button">
        <MenuIcon
          className="menu-icon"
          style={{ display: "inline", color: "#0c8868", fontSize: "35px" }}
        />
      </IconButton>
      <React.Fragment key="right">
        <Drawer
          className={classes.drawer}
          anchor="right"
          open={state["right"]}
          onClose={toggleDrawer("right", false)}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </>
  );
}
